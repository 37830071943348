import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"
import ButtonGroupMap from "../components/Button/ButtonGroupMap"
import MarkdownViewer from "@components/MarkdownViewer"
import NuvoImage from "../components/NuvoImage"

const Careers = ({ data, pageContext, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const language = pageContext.language

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />

        <div className="careers-page">
          <div className="columns has-text-centered heading-section">
            <div className="column is-3"></div>
            <div className="column">
              <h1>{post.heading}</h1>

              {post.blurb && <MarkdownViewer markdown={post.blurb} />}
            </div>
            <div className="column is-3"></div>
          </div>

          {post.topSection.hasThisSection && (
            <div className="columns careers-top-section">
              <div className="column is-3"></div>
              <div className="column">
                <MarkdownViewer markdown={post.topSection.text} />
                <NuvoImage
                  useAR
                  cloudName="nuvolum"
                  publicId={post.topSection.imageId}
                  width="auto"
                  responsive
                />
              </div>
              <div className="column is-3"></div>
            </div>
          )}

          <div className="columns careers-body">
            <div className="column is-3"></div>
            <div className="column">
              <MarkdownViewer markdown={post.body} />
            </div>
            <div className="column is-3"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query CareersPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        blurb
        topSection {
          hasThisSection
          imageId
          text
        }
        body
      }
    }
  }
`

export default Careers
